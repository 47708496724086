
/**
 * Transisitons drinks page sections
 * @param {string} section The section to animate 
 */
function slideAnimation(section) {
	if (section === 'drinks') {
		if ($("form[name=drinksForm] input:checkbox:checked").length > 0) {

			var selectedDrinks = $("form[name=drinksForm] input:checkbox:checked").map(function() {return this.value;}).get().join(",");
			$("input[name=drinks]").val(selectedDrinks);

			document.getElementById('drinksSection').classList.add('animation--slide-left');
			document.getElementById('purchaseAgain').classList.remove('hidden');
			setTimeout(function(){
				document.getElementById('drinksSection').style.display = 'none';
			}, 350)
		} else {
			$("form[name=drinksForm]").addClass('animated shake');
			setTimeout(function(){
				$("form[name=drinksForm]").removeClass('animated shake');
			}, 400);
		}
	}

	if (section === 'purchaseAgain') {
		if ($("form[name=purchaseAgainForm] input:radio:checked").length > 0) {

			var purchaseAgain = $("form[name=purchaseAgainForm] input:radio:checked").val();
			$("input[name=purchase]").val(purchaseAgain);
				
			document.getElementById('purchaseAgain').classList.add('animation--slide-left');
			document.getElementById('form').classList.remove('hidden');

			setTimeout(function(){
				document.getElementById('purchaseAgain').style.display = 'none';
			}, 350)
		} else {
			$("form[name=purchaseAgainForm]").addClass('animated shake');
			setTimeout(function() {
				$("form[name=purchaseAgainForm]").removeClass('animated shake');
			}, 400);
		}
	}
}

function checkAddressForm() {
	const inputs = document.getElementsByClassName('form-section__input');

	var errorCount = 0;

	for (var index = 0; index < inputs.length; index++) {
		const input = inputs[index];
		if (input.value == '' || input.value == undefined) {
			errorCount++;
			input.classList.add('animation--shake');
			(function(index) {
		        setTimeout(function(){
					index.classList.remove('animation--shake');
				}, 1000);
		    })(input);
			
		}
	}

	if (errorCount == 0) {
		checkTermsCheckbox();
	}
}

function checkTermsCheckbox() {
	const checkbox = document.getElementById('termsCheckbox');
	const container = document.getElementById('checkboxContainer');
	if (!checkbox.checked) {
		container.classList.add('animation--shake');
		setTimeout(function(){
			container.classList.remove('animation--shake');
		}, 1000);
	} else {
		window.location.href = '/pages/thankyou.html';
	}
}

function openTerms() {
	event.preventDefault();
	openModal('terms');
}
