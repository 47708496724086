/*
  This script handles the form submit and validation.
  */

  var _gametemplate = _gametemplate || {};

  _gametemplate.Module = function () {
	//init the template
	var init = function () {
		setViewPort();
		initOrientationCheck();
		onFormSubmit();
		fixAndroidAutoScroll();
		onDrinkSelect();
		displayCookieBannerOneTime();
		onCollegeChange();
		addChangeEventListeners();
		$('#save_btn').hide();
		// $(document).ready(function() {
		// 	selectCarrerDropDownSetup();
		// });	
	};


	var selectCarrerDropDownSetup = function(){
		$('#career').select2();
	};


	var saveData = function(){

		$('.loading').show();
		$('#save_btn').prop("disabled",true);

		var soc = $('#jobs').val() || $('#career').val();
		var onet_code = $('#us_jobs').val();
		var career_id = $('#career').find(':selected').attr('id');
		var title = $("#us_jobs option:selected").text();

		if(!(soc || onet_code || career_id || title)){
			return;
		}


		$.ajax({
	      type: "POST",
	      dataType: 'json',
	      async:true,
	      url: base_url + 'save_onet_data',
	      data: { 
	      	csrf_devclever_launchpad_api_test:ce,
	      	soc:soc,
	      	title:title,
	      	onet_code:onet_code,
	      	career_id:career_id
	      },
	      success: function(v){
	        ce = v.csrf;
	        console.log(v);
	        $('#save_btn').prop("disabled",false);
	        $('.loading').hide();
	        $('.status').text('Saving completed successfully.');
	        setTimeout(function(){
	        	$('.status').text('');
	        },2000);
	      },
	      error:function(v){
	      	console.log(v);
	      	$('#save_btn').prop("disabled",false);
	      	$('.loading').hide();
	      	$('.status').text('Saving failed. Please try agian.');
	        setTimeout(function(){
	        	$('.status').text('');
	        },2000);
	       //window.location = base_url;
	      }
	    });

	};

	var searchCareer = function(){

		$('#career_btn').prop("disabled",true);

		var keyword = $('#career').val().trim();

		if(!keyword){
			return;
		}

		$('#save_btn').hide();
		$('#jobs').empty();
	    $('#us_jobs').empty();
	    $('.college-container').empty();
	    $('.apprenticeship-container').empty();
	    $('.apprenticeship-container-gov').empty();
    	//.append('<option selected="selected" value="whatever">text</option>')

	    $.ajax({
	      type: "POST",
	      dataType: 'json',
	      async:true,
	      url: base_url + 'get_onet_codes',
	      data: { csrf_devclever_launchpad_api_test:ce,soc:keyword},
	      success: function(v){
	        ce = v.csrf;
	        console.log(v);
	        if(v.length == 0){

	        	$('#us_jobs').append('<option value="" selected>No data available</option>');

	        }else{

	        	$('#us_jobs').append('<option value="" selected>Select US job (ONET)</option>');
	        	$('#us_jobs').append(v.data);
	        }
	        $('#career_btn').prop("disabled",false);
	      },
	      error:function(v){
	      	console.log(v);
	      	$('#career_btn').prop("disabled",false);
	       //window.location = base_url;
	      }
	    });
	};


	var searchKeyword = function(){
		
		$('#key_btn').prop("disabled",true);

		var keyword = $('input[name=keyword]').val().trim();

		if(!keyword){
			toggleErrorMessage('input[name=keyword]',false);
			return;
		}

		$('#save_btn').hide();
	    $('#jobs').empty();
	    $('#us_jobs').empty();
	    $('.college-container').empty();
	    $('.apprenticeship-container').empty();
	    $('.apprenticeship-container-gov').empty();
    	//.append('<option selected="selected" value="whatever">text</option>')

	    $.ajax({
	      type: "POST",
	      dataType: 'json',
	      async:true,
	      url: base_url + 'get_soc_codes',
	      data: { csrf_devclever_launchpad_api_test:ce,keyword:keyword},
	      success: function(v){
	        ce = v.csrf;
	        console.log(v);
	        if(v.length == 0){

	        	$('#jobs').append('<option value="" selected>No data available</option>');

	        }else{

	        	$('#jobs').append('<option value="" selected>Select UK job (SOC)</option>');
	        	$('#jobs').append(v.data);
	        }
	        $('#key_btn').prop("disabled",false);
	      },
	      error:function(v){
	      	console.log(v);
	      	$('#key_btn').prop("disabled",false);
	       //window.location = base_url;
	      }
	    });
	};

	var onSocChange = function(soc){

		if(!soc){
			return;
		}
		
		$('#save_btn').hide();
		$('#us_jobs').empty();
		$('.college-container').empty();
	    $('.apprenticeship-container').empty();
	    $('.apprenticeship-container-gov').empty();
    	//.append('<option selected="selected" value="whatever">text</option>')

	    $.ajax({
	      type: "POST",
	      dataType: 'json',
	      async:true,
	      url: base_url + 'get_onet_codes',
	      data: { csrf_devclever_launchpad_api_test:ce,soc:soc},
	      success: function(v){
	        ce = v.csrf;

	        if(v.length == 0){

	        	$('#us_jobs').append('<option value="" selected>No data available</option>');

	        }else{

	        	$('#us_jobs').append('<option value="" selected>Select US job (ONET)</option>');
	        	$('#us_jobs').append(v.data);
	        }
	        

	        console.log(v);
	        
	      },
	      error:function(v){
	      	console.log(v);
	       //window.location = base_url;
	      }
	    });
	};

	var onONETChange = function(onet,title){
		$('#save_btn').hide();
		getApprenticeships(onet);
		getApprenticeshipsGove(onet,title);
		getColleges(onet);

	};

	var getApprenticeships = function(onet){
		if(!onet){
			return;
		}
		
		$('.apprenticeship-container').empty();
		$('.apprenticeship-container').append('<div class="apprenticeship"><p>Loading data...</p></div>');

		$.ajax({
			beforeSend: function(xhr) {
		  	if(activeAjaxConnections <= 3) activeAjaxConnections++;
	      },
	      type: "POST",
	      dataType: 'json',
	      async:true,
	      url: base_url + 'get_apprenticeships',
	      data: { onet:onet},
	      success: function(v){

	        $('.apprenticeship-container').empty();

			if(v.length == 0){

	        	$('.apprenticeship-container').append('<div class="apprenticeship"><p>No apprenticeship data available.</p></div>');

	        }else{
	        	returnedData = true;
	        	
	        	
	        	$('.apprenticeship-container').append(v.data);
	        	
	        }

	        activeAjaxConnections--;
	        enableSaveBtn();	
	        console.log(v);
	        
	      },
	      error:function(v){

	      	$('.apprenticeship-container').empty();
	      	$('.apprenticeship-container').append('<div class="apprenticeship"><p>No apprenticeship data available.</p></div>');
	      	console.log(v);
	       //window.location = base_url;
	      }
	    });
	};

	var getApprenticeshipsGove = function(onet,title){
		if(!onet || !title){
			return;
		}
		
		$('.apprenticeship-container-gov').empty();
		$('.apprenticeship-container-gov').append('<div class="apprenticeship"><p>Loading data...</p></div>');

		$.ajax({
			beforeSend: function(xhr) {
		  	if(activeAjaxConnections <= 3) activeAjaxConnections++;
	      },
	      type: "POST",
	      dataType: 'json',
	      async:true,
	      url: base_url + 'get_apprenticeships_gove',
	      data: { onet:onet,title:title},
	      success: function(v){

	        $('.apprenticeship-container-gov').empty();

			if(v.length == 0){

	        	$('.apprenticeship-container-gov').append('<div class="apprenticeship"><p>No apprenticeship data available.</p></div>');

	        }else{
	        	returnedData = true;
	        	
	        	
	        	$('.apprenticeship-container-gov').append(v.data);
	        	
	        }

	        activeAjaxConnections--;
	        enableSaveBtn();	
	        console.log(v);
	        
	      },
	      error:function(v){

	      	$('.apprenticeship-container-gov').empty();
	      	$('.apprenticeship-container-gov').append('<div class="apprenticeship"><p>No apprenticeship data available.</p></div>');
	      	console.log(v);
	       //window.location = base_url;
	      }
	    });
	};

	

	var getColleges = function(onet){
		if(!onet){
			return;
		}

		$('.college-container').empty();
		$('.college-container').append('<div class="college"><p>Loading data...</p></div>');

		$.ajax({

		  beforeSend: function(xhr) {
		  	if(activeAjaxConnections <= 3) activeAjaxConnections++;
	      },
	      type: "POST",
	      dataType: 'json',
	      async:true,
	      url: base_url + 'get_colleges',
	      data: { onet:onet},
	      success: function(v){

	        $('.college-container').empty();
			if(v.length == 0){

	        	$('.college-container').append('<div class="college"><p>No college data available.</p></div>');

	        }else{

	        	returnedData = true;
	        	
	        	
	        	$('.college-container').append(v.data);
	        }

	        activeAjaxConnections--;
	        enableSaveBtn();	
	        console.log(v);
	        
	      },
	      error:function(v){

	      	$('.college-container').empty();
	      	$('.college-container').append('<div class="college"><p>No college data available.</p></div>');
	      	console.log(v);
	       //window.location = base_url;
	      }
	    });
	};

	var activeAjaxConnections = 0;
	var returnedData = false;
	var enableSaveBtn = function(){
		console.log(activeAjaxConnections+" "+returnedData);
		if(activeAjaxConnections == 0 && returnedData){
			$('#save_btn').show();
			returnedData = false;
		}	
	};

	var addChangeEventListeners = function(){
		
		$(".uk-jobs-holder").on('change','#jobs',function () { 

			onSocChange($(this).val());

		});


		$(".us-jobs-holder").on('change','#us_jobs',function () { 
			
			onONETChange($(this).val(),$("#us_jobs option:selected").text());
			//console.log($(this).val());

		});
	};

	


	var onCollegeChange = function(){

		$('#collegeSelect').on('change', function() {
		 if($(this).val()){
		  	$(this).removeClass('form-section__select--empt');
		  }
		});
	};

	var initOrientationCheck = function () {
		//check for device orientation
		if (window.DeviceOrientationEvent) {
			window.addEventListener('orientationchange', doOnOrientationChange); // Initial execution if needed

			doOnOrientationChange();
		} else {
			console.log("Sorry, your browser doesn't support Device Orientation");
		}
	};

	var displayCookieBannerOneTime = function(){
	    if(!localStorage.getItem('cookie')) {
	      $('.cookie__policy').css('display','flex');
	    }
	};

	var hideCookiePolicy = function(){
	    localStorage.setItem('cookie', 1);
	    $('.cookie__policy').hide();
	  };


	var onTermsCheck = function () {
		$('#accept').on('click', function (event) {
			$('#termsmodal').modal('show');
			event.preventDefault();
			event.stopPropagation();
			return false;
		});
	};

	var fixAndroidAutoScroll = function(){
		if(/Android/.test(navigator.appVersion)) {
		   window.addEventListener("resize", function() {
		     if(document.activeElement.tagName == "INPUT") {
		       document.activeElement.scrollIntoView();
		     }
		  })
		} 
	};

	var acceptTerms = function (v) {
		$('#termsmodal').modal('hide');

		if (v == 1) {
			$('#accept').prop('checked', true);
		} else {
			$('#accept').prop('checked', false);
		} //termsCheck();

	};

	var termsHint = function () {
		$('#termsHint').modal('hide');
	};

	var invalidCode = function () {
		$('#invalid_code').modal('hide');
	};

	var reload = function () {
		window.location.reload();
	};

	var onTermsClick = function () {
		$('#termsmodal').modal('show');
	};

	var validateEmail = function (email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	};

	var validateDOB = function (dob) {
		var re = /^((0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/[12]\d{3})/;
		return re.test(dob);
	};

	var validateToken = function (token) {
		var re = /^[a-zA-Z0-9]{8}$/;
		return re.test(token);
	};

	var onFormSubmit = function () {
		$("#addressForm").on("submit", function (e) {
			//e.preventDefault();
			return validCheck();
		});
	};

	var validCheck = function () {
		var isValid = true;
		var firstName = $('form[name=addressForm] input[name=firstName]').val().trim();
		var lastName = $('form[name=addressForm] input[name=lastName]').val().trim();
		var email = $('form[name=addressForm] input[name=email]').val().trim();
		var college = $('form[name=addressForm] select[name=college] option:selected').val().trim();

		if (!(firstName && firstName.length > 2 && firstName.length < 50 && !validateEmail(firstName))) {
			toggleErrorMessage('input[name=firstName]',true);
			isValid = isValid ? false : isValid;

		}else{
			toggleErrorMessage('input[name=firstName]',false);
		}

		if (!(lastName && lastName.length > 2 && lastName.length < 50 && !validateEmail(lastName))) {
			toggleErrorMessage('input[name=lastName]',true);
			isValid = isValid ? false : isValid;

		}else{
			toggleErrorMessage('input[name=lastName]',false);
		}

		if (!(college)) {
			toggleErrorMessage('select[name=college]',true);
			isValid = isValid ? false : isValid;
		}else{
			toggleErrorMessage('select[name=college]',false);
		}

		if (!validateEmail(email)) {
			toggleErrorMessage('input[name=email]',true);
			isValid = isValid ? false : isValid;
		}else{
			toggleErrorMessage('input[name=email]',false);
		}

		if (!$('#termsCheckbox').is(':checked')) {
			$('#checkboxContainer').addClass('shake animated');
			setTimeout(function () {
				$('#checkboxContainer').removeClass('shake animated');
			}, 700);
			isValid = isValid ? false : isValid;
		}

		return isValid;
	}; 

	var toggleErrorMessage = function(domm,visible){

		var errorMessage = $(domm).parent().find('.form-section__span-error');
		var errorFeild = $(domm).parent().find('input,select');

		if(visible){
			errorFeild.addClass('form-section__input--error');
			errorMessage.css('display', 'block');

		}else{
			errorFeild.removeClass('form-section__input--error');
			errorMessage.hide();
		}
	};

	var doOnOrientationChange = function () {
		switch (window.orientation) {
			case -90:
			case 90:
			$('.orientation').show();
			break;

			default:
			$('.orientation').hide();
			break;
		}
	};

	var onDrinkSelect = function(){
		$('.drinks-section__option').click(function(){
			$(this).find('input[type=checkbox]').prop("checked", !$(this).find('input[type=checkbox]').prop("checked"));
		});

		$('input[name=drinks]').click(function (e) {
			e.stopPropagation();
		});
	};

	var setViewPort = function () {
		setTimeout(function () {
			var viewheight = $(window).height();
			var viewwidth = $(window).width();
			var viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewheight + "px, width=" + viewwidth + "px, initial-scale=1.0");
		}, 300);
	};

	var closeterms = function () {
		$('#buyonegetone').modal('hide');
		$('#freepudding').modal('hide');
		$('#freedrink').modal('hide');
		$('#freestarter').modal('hide'); //$('#prize_voucher').modal('show');
	};

	var showDetailsEnter = function () {
		$('#wait_sec').modal('hide');
		setActiveView('enter-prizedraw');
	};


	var oPublic = {
		init: init,
		onTermsClick: onTermsClick,
		acceptTerms: acceptTerms,
		reload: reload,
		termsHint: termsHint,
		showDetailsEnter: showDetailsEnter,
		invalidCode: invalidCode,
		closeterms: closeterms,
		slideAnimation: slideAnimation,
		hideCookiePolicy:hideCookiePolicy,
		searchKeyword:searchKeyword,
		searchCareer:searchCareer,
		saveData:saveData
	};
	return oPublic;
}();

_gametemplate.Module.init();
